import React from "react";
import { message } from "antd";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import SearchAndList from "./components/SearchAndList";

const SimilarModal = Loader.loadBusinessComponent("ObjectMapPersonnelComponent", "SimilarModal");

@withRouter
@Decorator.businessProvider("flowPerson", "residentPerson", "tab")
@observer
class CommunityListContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Math.random(),
      similarVisible: false,
      relationData: {}
    };
    this.infiniteRef = React.createRef();
    this.listRef = React.createRef();
  }

    componentWillReceiveProps(nextProps){
      let {
        LongLiveList,
        RegisUnappList,
        allList
      } = this.props;
      if((nextProps.LongLiveList !== LongLiveList) || (nextProps.RegisUnappList !== RegisUnappList) || (nextProps.allList !== allList)){
        this.listRef && this.listRef.current && this.listRef.current.forceUpdateGrid()
      }
    }

  /**条件查询列表 */
  onTypeChange = type => {
    let { activeKey, recoverInitPage, requestData } = this.props;
    if (!type) {
      this.mergeSearchData();
    }
    recoverInitPage && recoverInitPage();
    requestData && requestData(activeKey, false, true);
  };
  /**跳转详情页面 */
  handlePageJump = datas => {
    console.time("test");
    const { tab, location, isCover } = this.props;
    let moduleName = "objectMapPersonnelDetail",
      id = "",
      type = "";
    if (isCover) {
      /**登记人口 */
      id = datas.id;
      if (datas.hasAid) {
        type = "ploy";
      } else {
        type = "entry";
      }
      tab.openDetailPage({
        moduleName,
        value: id,
        data: { id },
        beforeAction: async () => {
          await window.LM_DB.add("parameter", { id, url: datas.personInfoUrl, type });
        }
      });
    } else {
      /**未登记人口 */
      Service.person.queryPersonBindInfo({ aid: datas.aid, personId: "" }).then(res => {
        const { personId, aids = [] } = res.data;
        const AIDS_LEN = aids.length > 0;
        if (personId && AIDS_LEN) {
          id = personId;
          type = "ploy";
        }
        if (personId && !AIDS_LEN) {
          id = personId;
          type = "entry";
        }
        if (!personId && !AIDS_LEN) {
          id = datas.aid;
          type = "aid";
        }
        tab.openDetailPage({
          moduleName,
          value: id,
          data: { id },
          beforeAction: async () => {
            await window.LM_DB.add("parameter", { id, url: datas.personInfoUrl, type });
          }
        });
      });
    }
  };
  /**以图搜图搜索 */
  onTypeChangeAnother = () => {
    const { activeKey, isCover } = this.props;
    const { residentPerson, flowPerson } = this.props;
    if (isCover) {
      residentPerson.editSearchData({ offset: 0 }, activeKey);
    } else {
      flowPerson.editSearchData({ offset: 0 }, activeKey);
    }
    this.onTypeChange(true);
  };
  /**关注 */
  onFollow = (data, e) => {
    Utils.stopPropagation(e);
    Service.person
      .setOrCancelFocus({
        personId: data.id,
        aid: data.aid,
        isFocus: data.isFocus ? false : true
      })
      .then(() => {
        data.isFocus = !data.isFocus;
        SocketEmitter.emit(SocketEmitter.eventName.updatePerson, data);
      });
  };
  /**关联 */
  onRelation = (data, e) => {
    Utils.stopPropagation(e);
    this.setState({
      relationData: data,
      similarVisible: true
    });
  };
  // /**处理绑定aid */
  // handleSimilarOK = data => {
  //   let { relationData } = this.state;
  //   Service.person.addRelationVids({ aidBindParams: data, personId: relationData.id }).then(() => {
  //     //SocketEmitter.emit(SocketEmitter.eventName.updatePerson);
  //     this.setState({
  //       similarVisible: false
  //     });
  //     message.info('关联成功');
  //   });
  // };
  handleSimilarCancel = async () => {
    await this.setState({ similarVisible: false });
    // SocketEmitter.emit(SocketEmitter.eventName.updatePerson);
  };
  /**添加indexDb */
  mergeSearchData = () => {
    let { activeKey, mergeSearchData } = this.props;
    const id = Utils.uuid();
    mergeSearchData && mergeSearchData(activeKey, id);
  };
  render() {
    let {
      activeKey,
      LongLiveList,
      RegisUnappList,
      id,
      longLoading,
      type,
      allList,
      total,
      isCover,
      requestData,
      scrollId,
      LongLoadMore
    } = this.props;
    let { similarVisible, relationData } = this.state;
    return (
      <React.Fragment>
        <SearchAndList
          {...this.state}
          setImage={this.setImage}
          list={type == "resident" ? LongLiveList : type == "float" ? RegisUnappList : allList}
          id={id}
          ref={this.listRef}
          total={total}
          onTypeChange={this.onTypeChange}
          activeKey={activeKey}
          isCover={isCover}
          loading={longLoading}
          requestData={requestData}
          listType={type == "resident" ? 1 : type == "float" ? 2 : 3}
          onTypeChangeAnother={this.onTypeChangeAnother}
          onRelation={this.onRelation}
          onFollow={this.onFollow}
          onClick={this.handlePageJump}
          keyId={scrollId}
          hasLoadMore={LongLoadMore}
        />
        {similarVisible && (
          <SimilarModal
            visible={similarVisible}
            onOk={this.handleSimilarOK}
            onCancel={this.handleSimilarCancel}
            personData={relationData}
          />
        )}
      </React.Fragment>
    );
  }
}
export default CommunityListContent;
