import React from 'react';
import { Spin } from 'antd';
import { observer } from 'mobx-react';

const SearchBoxCollection = Loader.loadBusinessComponent('SearchBoxCollection');
const CommunityImageInput = Loader.loadBusinessComponent('CommunityImageInput');
const CommunityCard = Loader.loadBusinessComponent('Card', 'ObjectMapPersonnelCard');
const NoData = Loader.loadBaseComponent('NoData');
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');

@Decorator.businessProvider('flowPerson', 'residentPerson', 'tab')
@observer
class SearchAndList extends React.Component {
   constructor(props){
    super(props);
    this.state={
      imgUrl: '',
      extraImgurl: '',
      allImgurl: '',
      val: '',
      extraVal: '',
      allVal: '',
    }
  }
  //存储图片用作回显
  setImage = img => {
    let {listType} = this.props
    if(listType == 1){
      this.setState({imgUrl:img})
    }
    if(listType == 2){
      this.setState({extraImgurl:img})
    }
    if(listType == 3){
      this.setState({allImgurl:img})
    }
    
  }
   //存储图片用作回显
   setKeywords = val => {
    let {listType} = this.props
    if(listType == 1){
      this.setState({val})
    }
    if(listType == 2){
      this.setState({extraVal:val})
    }
    if(listType == 3){
      this.setState({allVal:val})
    }
    
  }

  onTypeChangeAnother = () => {
    this.props.onTypeChangeAnother();
  };
  /**获取图片结构化信息 */
  getFeature = feature => {
    const { activeKey, isCover, residentPerson, flowPerson,requestData } = this.props;
    if (isCover) {
      residentPerson.editSearchData({ faceFeature: feature }, activeKey).then(res => requestData(activeKey,false,false));
    } else {
      flowPerson.editSearchData({ faceFeature: feature }, activeKey).then(res => requestData(activeKey,false,false));
    }
  };
  /**将人员特征和关键字置空 */
  deleteImg = type => {
    const { activeKey, residentPerson, flowPerson, isCover, onTypeChange } = this.props;
    if (isCover) {
      residentPerson.editSearchData({ faceFeature: null, keywords: null, offset: 0 }, activeKey);
    } else {
      flowPerson.editSearchData({ faceFeature: null, keywords: null, offset: 0 }, activeKey);
    }
    if (!type) {
      onTypeChange(true);
    }
  };
  requestData = () => {
    let { total, listType, requestData } = this.props;
    if (total > 100) {
      requestData(listType, true);
    }
  };
  render() {
    let { onTypeChange, activeKey, id, isCover, list, loading, listType, onRelation, onFollow, onClick, keyId, hasLoadMore } = this.props;
    return (
      <React.Fragment>
        <div className="float-community">
          <SearchBoxCollection onTypeChange={onTypeChange} activeKey={activeKey} id={id} isCover={isCover} Loadtype={listType} />
          <CommunityImageInput
          {...this.state}
          setKeywords={this.setKeywords}
          setImage={this.setImage}
            key={activeKey}
            getFeature={this.getFeature.bind(this)}
            search={this.onTypeChangeAnother}
            activeKey={activeKey}
            deleteImg={this.deleteImg}
            isCover={isCover}
            Loadtype={listType}
          />
        </div>
        <div className="float-communty-tab-scroll" ref="backonereal">
          {list && list.length ? (
            <Spin spinning={loading}>
              {activeKey == listType && (
                <InfiniteScrollLayout
                  rowSize={4}
                  itemWidth={344}
                  itemHeight={262}
                  ref={this.infiniteRef}
                  key={keyId}
                  pdWidth={16}
                  hasBackTop={true}
                  data={list}
                  hasLoadMore={hasLoadMore}
                  loadMore={this.requestData}
                  renderItem={(item, index) => (
                    <CommunityCard
                      data={item}
                      key={index}
                      onRelation={onRelation}
                      onFollow={onFollow}
                      onClick={onClick}
                      imgUrl={item.portraitPicUrl || item.url}
                      personId={item.id}
                      id={item.aid}
                      aid={item.aid}
                      address={item.presentAddress}
                      lastTime={item.recentTime}
                      lastAddress={item.recentAddress}
                      isFocus={item.isFocus}
                      countTitle={isCover ? '距离本场所上次出现' : `${item.days}天内出现`}
                      tags={item.tagCodes || []}
                      personName={item.name}
                      relationShow={item.hasAid}
                      countDay={item.continuousUncapturedDays || item.appearDaysInMonth}
                    />
                  )}
                />
              )}
            </Spin>
          ) : (
            <React.Fragment>
              {loading ? (
                <div style={{ position: 'absolute', left: '48%', top: '23%' }}>
                  <Spin />
                </div>
              ) : (
                <NoData />
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default React.forwardRef((props, ref) => <SearchAndList {...props} forwardRef={ref} />);
