(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("mobxReact"), require("ReactRouterDOM"));
	else if(typeof define === 'function' && define.amd)
		define("CommunityListContent", ["React", "antd", "mobxReact", "ReactRouterDOM"], factory);
	else if(typeof exports === 'object')
		exports["CommunityListContent"] = factory(require("React"), require("antd"), require("mobxReact"), require("ReactRouterDOM"));
	else
		root["CommunityListContent"] = factory(root["React"], root["antd"], root["mobxReact"], root["ReactRouterDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 